<template>
  <div>
    <v-card>
      <v-card-title>
        <div>
          <div class="headline">Brands</div>
          <span class="subtitle-1 light-grey"></span>
        </div>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="businesses"
          hide-default-footer
          :items-per-page="50"

        >
          <v-progress-linear
            slot="progress"
            color="primary"
            indeterminate
          ></v-progress-linear>
          <template v-slot:body="{ items }">
            <tbody>
              <tr v-for="item in items" :key="item.uuid">
                <td>
                  <router-link
                    :to="{ name: 'business', params: { id: item.uuid } }"
                  >
                    {{ item.user.first_name }} {{ item.user.last_name }}
                  </router-link>
                  <br />
                  <span class="primary--text">{{ item.display_name }}</span>
                </td>
                <td>
                  <v-icon v-if="item.pixel_approved_at" color="success" small
                    >fal fa-check</v-icon
                  >
                  <v-icon v-else color="error" small>fal fa-times</v-icon>
                </td>
                <td>
                  <v-icon
                    v-if="item.promotion_code_tracking_approved_at"
                    color="success"
                    small
                    >fal fa-check</v-icon
                  >
                  <v-icon v-else color="error" small>fal fa-times</v-icon>
                </td>
                <td class="text-right"></td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
        <v-pagination
          v-model="currPage"
          class="my-2"
          :length="total"
          :total-visible="7"
          @input="handlePageChange"
        ></v-pagination>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  data: () => ({
    businesses: [],
    total: null,
    currPage: 1,
    headers: [
      { text: "Name", sortable: false },
      { text: "Approved pixel", sortable: false },
      { text: "Approved promotion code tracking", sortable: false },
      { text: "", sortable: false }
    ]
  }),
  computed: {
    ...mapState("core/auth", ["user"])
  },
  methods: {
    ...mapActions("core", ["getBusinesses"]),
    handlePageChange(value) {
      this.getBusinessList(value);
    },
    getBusinessList(page = 1) {
      const params = {
        search: "",
        limit: 50,
        page
      };
      this.getBusinesses(params).then(
        businesses => {
          (this.total = businesses.lastPage),
            (this.businesses = businesses.response);
        },
        () => {
          this.setSnackError("Something went wrong.");
        }
      );
    }
  },
  created() {
    this.getBusinessList();
  }
};
</script>
